<template>
  <div class="zjfheader" id="zjfheader">
    <div class="left">
      <img src="../../../assets/student/zjflogo.png" @click="router.replace({name: 'home'})" alt="">
      <span v-for="item in header_data" :key="item.title" @click="goback(item)"> {{ item.title }} </span>
    </div>
    <el-dropdown trigger="click" @command="returnLogin" :hide-on-click="false">
      <div class="el-dropdown-link right">
        <svg-icon icon="icon7" v-if="fellow" style="position: relative;top: -10px;left: 15px"></svg-icon>
        <img src="../../../assets/student/zjftouxiang.png" alt="">
        <span> {{ store.state.userInfo.xm }} </span>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="20">设置</el-dropdown-item>
          <el-dropdown-item command="15">修改密码</el-dropdown-item>
          <el-dropdown-item command="10">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <div class="right">
      <img src="../../../assets/student/zjftouxiang.png" alt="">
      <span> {{ store.state.userInfo.realname || '未登录' }} </span>
    </div> -->
    <Signout :exit_data="exit_data" v-if="exit_data.isShow"/>
    <AlterPassword ref="formRef"/>
  </div>
</template>
<script>
import {reactive, ref,toRefs} from 'vue-demi';
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import Signout from './signout.vue'
import {ElMessage} from 'element-plus';
import AlterPassword from "@/components/aboutPassword/alterPassword.vue";
import {computed} from "vue";

export default {
  components: {
    AlterPassword,
    Signout
  },
  props: {
    header_data: {
      type: Array,
      default: null,
    },
    noClicking: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const fellow = computed(() => store.state.fellow);
    const formRef = ref(null)
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      exit_data: { // 控制退出登录弹窗显示隐藏
        isShow: false
      }
    })
    const goback = (val) => {
      if (!val.path) return;
      // 地址栏有参数，拆分参数为键值对，路由传参
      if (val.path.includes('?')) {
        var params = {}
        let str = val.path.substring(val.path.indexOf('?') + 1)
        var vars = str.split("&");
        vars.forEach(item => {
          params[item.split('=')[0]] = item.split('=')[1]
        })
        router.replace({path: val.path, query: params});
        // 没有参数，直接跳路由
      } else {
        router.replace({path: val.path});
      }
    };
    const returnLogin = (val) => {
      console.log(val)
      if (val == 10) {
        data.exit_data.isShow = true
      } else if (val == 20) {
        if (props.noClicking) {
          ElMessage({
            type: 'warning',
            message: '学习过程中不可以点击设置哦'
          })
          return false
        }
        router.replace({path: '/setUpPage/information'})
      } else {
        // 修改密码
        formRef.value.dialogVisible = true;
      }
    }
    return {
      formRef,
      router,
      goback,
      store,
      returnLogin,
      fellow,
      ...toRefs(data)
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-link{
  display: flex;
}
// @media (min-height: 700px) {
//   #zjfheader {
//     height: 80px;
//     padding: 0.84vw 2vw;
//     .left {
//       cursor: pointer;
//       img {
//         display: block;
//         width: 2.5vw;
//         height: 2.5vw;
//         margin-right: 1.5vw;
//         float: left;
//       }
//       span {
//         font-size: 1.3vw;
//         line-height: 2.5vw;
//       }
//       span:last-child {
//         font-size: 1.2vw;
//         line-height: 2.5vw;
//       }
//     }
//     .right {
//       img {
//         display: block;
//         width: 2.1vw;
//         height: 2.1vw;
//         float: left;
//       }
//       span {
//         font-size: 1.3vw;
//         margin-left: 14px;
//         float: left;
//         line-height: 2.1vw;
//       }
//     }
//   }
// }
.zjfheader {
  width: 100%;
  height: 5.2vw;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  padding: 1.14vw 2.6vw;
  display: flex;
  justify-content: space-between;
  background: url("../../../assets/student/zjfding.png") no-repeat;
  background-size: 100% 100%;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);

  .left {
    img {
      display: block;
      width: 3vw;
      height: 3vw;
      margin-right: 1.5vw;
      float: left;
    }

    span {
      font-size: 1.56vw;
      line-height: 3vw;
    }

    span:last-child {
      font-size: 1.3vw;
    }
  }

  .right {
    img {
      display: block;
      width: 2.6vw;
      height: 2.6vw;
      float: left;
    }

    span {
      font-size: 1.56vw;
      margin-left: 14px;
      float: left;
      line-height: 3vw;
    }
  }
}
</style>